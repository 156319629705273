.mr_top_bottom {
  margin-top: 20px;
  margin-bottom: 20px;
}

.fc_main_header_wrapper {
  display: block;
  width: 100%;
  padding: 35px;
  background: #ffffff;
  box-shadow: 0px 4px 20px #0027761c;
  border-radius: 8px;
}

.fc_main_header_wrapper .TopCaption {
  font-size: 20px;
  margin-bottom: 15px;
}

.header_sub_content {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.header_button_main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  /* justify-self: end; */
}

.fc_main_header_wrapper .TopCaption span {
  font-size: 12px;
  color: #404855;
  font-family: "Poppins-Light";
}

.bottom_statics_box {
  display: grid;
  grid-template-columns: 60% 20% 10%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bottom_statics_box .display_week {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}

.bottom_statics_box .splitter_display {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.bottom_statics_box .logo_flight {
  align-self: center;
}

.fc_main_header_wrapper .refreshBtn,
.rankingBtn,
.thumbsButton,
.emailButton,.documentButton {
  font-size: 28px;
}
/* .fc_main_header_wrapper  {
  font-size: 28px;
} */
.fc_main_header_wrapper .refreshBtn,
.rankingBtn svg {
  background: #002776;
  color: white;
  border-radius: 15px;
  padding: 8px;
  cursor: pointer;
}
.emailButton svg {
  background: rgba(0, 115, 129, 0.7);
  color: #fff;
  padding: 8px;
  border-radius: 15px;
  cursor: pointer;
}
.documentButton svg {
  background: rgba(0, 115, 129, 0.2);
  color: #fff;
  padding: 8px;
  border-radius: 15px;
  cursor: pointer;
}

polygon,
g {
  fill: white !important;
}
.fc_main_header_wrapper .thumbsButton svg {
  background: white;
  /* color: white; */
  border-radius: 15px;
  padding: 8px;
  cursor: pointer;
  color: black;
}
.fc_main_header_wrapper .thumbsButton.producer-down-btn svg {
  color: red;
}
.fc_main_header_wrapper .thumbsButton.admin-down-btn svg {
  color: blue;
}
.fc_main_header_wrapper .thumbsButton.admin-up-btn svg {
  color: green;
}
.fc_main_header_wrapper .fc_btn {
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 25px;
  border: 1px solid transparent;
  color: #ffffff;
  width: 100%;
  height: 45px;
  cursor: pointer;
}
.fc_main_header_wrapper .fc_btn:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

.fc_btn.red {
  background-color: red;
}

.fc_btn.info {
  background-color: #002776;
}
.fc_btn.green {
  background-color: green;
}

.wrap_btn_drop {
  display: grid;
  grid-template-columns: 58% 38%;
  gap: 5%;
}

.matrixModelSelectorMain .matrix_selector_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.matrixModelSelectorMain .matrix_selector_wrapper select {
  width: 100% !important;
}

.currencySelector .cus_input label {
  margin-right: 14px;
}

.currencySelector .cus_input input {
  min-width: 200px;
}
.thumbs-button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* Responsiveness goes Here */

@media (max-width: 767px) {
  .fc_main_header_wrapper {
    padding: 15px;
  }
  .fc_main_header_wrapper .TopCaption {
    font-size: 15px;
  }

  .bottom_statics_box {
    grid-template-columns: 1fr;
  }

  .bottom_statics_box .display_week {
    grid-template-columns: 1fr;
  }

  .bottom_statics_box .splitter_display {
    grid-template-columns: 1fr;
  }

  .header_button_main {
    grid-template-columns: 1fr;
  }

  .wrap_btn_drop {
    grid-template-columns: 1fr;
  }

  .matrixModelSelectorMain .matrix_selector_wrapper {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) and (max-width: 100px) {
  .wrap_btn_drop {
    grid-template-columns: 1fr;
  }

  .matrixModelSelectorMain .matrix_selector_wrapper {
    grid-template-columns: 1fr;
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 50px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 2px 0;
  /* border-radius: 6px; */
  position: absolute;
  z-index: 1;
  font-size: 9px;
  bottom: -11px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.fc_btn.adPadsFixFc1 {
  padding: 8px 10px;
}

.wrap_btn_drop .matrixModelSelectorMain .matrix_selector_wrapper {
  align-items: baseline;
}




/** bubble selector
*/
.bubble-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 650px;
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px #0027761c;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: 5px;
}
.bubble-main{
  width: '100%';
}
.bubble-main.right{
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  align-items: center;
}
.bubble-main.right .save_button {
  width: 80%;
}
.bubble-main.right .save_button p{
  margin-top:0px;
  color: #FFF;
}
.bubble-main-wrap{
  gap: 10px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0px 15px;
}
.bubble-main p{
  color:#404855;
  font-size: 13px;
  margin:0;
  text-align: center;
}
.bubble-main p.time_left_wrap{
  font-size: 11px;
}
.bubble-main p:last-child{
  color: #999;
  margin-top: 10px;
}
.bubble-main-wrap.history_buttons{
  width: 80px;
  opacity: 0.6;
}
.bubbles {
  height: 35px;
  width: 35px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #002776;
  font-size: 13px;
}
.bubbles.active{
  background: #002776;
  color: #fff;
  cursor: pointer;
}
.bubbles.inactive{
  background: #fff;
  color: #002776;
  cursor: pointer;
}

.temp-bubble{
  background: lightskyblue;
  color: #fff;
}
.inactive_week.no_price{
  background: #fe443877;
  color: #fff;
  cursor:initial;
}
.bubbles.greyed_out{
  background-color:#d3d1d1;
  color: #fff;
  border: 1px solid #002776;
  /* opacity: 0.3; */

}
.add_button,.delete-button,.sigma_button{
  height: 40px;
  width: 40px;
  padding:5px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  
  font-size: 13px;
  color: #FFF;
  
  transition: 0.3s ease-in-out;
}
.add_button{
  background: #002776;
  border: 1px solid #002776;
}
.add_button:hover{
  background-color: #404855;
  cursor: pointer;
}
.add_button p {
  margin-top: 0px!important;
}
.delete-button{
  background: #e74c3c;
  border: 1px solid #e74c3c;
}
.delete-button:hover{
  background: #b53c2e;
  cursor: pointer;
}
.sigma_button{
  background: gray;
}
.sigma_button:hover{
  cursor: pointer;
}

.bubbles.disabled{
  background: #999;
  opacity: 0.3;
}
.bubbles.active.disabled{
  background: #002776;
  color: #fff;
  cursor: pointer;
  opacity: 1;
}
.bubbles.active.disabled:hover{
cursor: pointer;
}
.bubbles.disabled:hover{
  cursor: not-allowed;
}

@media only screen and (max-width: 1680px) {
  .bubble-main p{
      font-size: 13px;
  }
  .bubble-main p.time_left_wrap{
      font-size: 11px;
  }
}